/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import Header from "./header"
import TimeLine from "../partialPages/TimeLine"

const Layout = ({
  children,
  pageInfo,
  hideTimeline = false,
  className = "",
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 bg-light">
          <Row noGutters className="justify-content-center">
            <Col>
              <Header
                siteTitle={data.site.siteMetadata.title}
                pageInfo={pageInfo}
              />
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <main className={className}> {children}</main>
            </Col>
          </Row>
        </Container>
        {!hideTimeline ? <TimeLine></TimeLine> : null}

        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <footer className="bg-dark " style={{ height: 50 }}>
                <span style={{ fontSize: "0.75rem" }}>
                  Copyright &copy; {new Date().getFullYear()} Vulcan AI Pte.
                  Ltd. All rights reserved.
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default Layout
