import React from "react"
import NavItem from "./navItem"
import { Navbar, Nav } from "react-bootstrap"
import AnimatedLink from "./AnimatedLink"
import logo from "../../static/images/logo.png"
const CustomNavbar = ({ pageInfo }) => {
  return (
    <>
      <Navbar expand="xl" variant="dark" fixed="top" id="site-navbar">
        <AnimatedLink to="/" className="link-no-style px-4">
          <Navbar.Brand as="span">
            <img src={logo} alt="Vulcan AI" style={{ width: 140 }} />
          </Navbar.Brand>
        </AnimatedLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="ml-auto navbar-right"
            activeKey={pageInfo && pageInfo.pageName}
          >
            {/* <NavItem route="industries" display="Industry 4.0" /> */}
            {/* <NavItem route="worksafe" display="WorkSafe" /> */}
            {/* <div
              className="link-no-style"
              style={{ cursor: "pointer" }}
              onClick={() => window.open("https://ceres.vulcan-ai.com/")}
            >
              <Nav.Link as="span">Vulcan Ceres</Nav.Link>
            </div>
            <NavItem route="products" display="AI Applications" />
            <NavItem route="platform" display="AI Platform" />
            <NavItem route="about" display="About Us" /> */}

            {/* <NavDropdown
              title="About Us"
              rootCloseEvent="click"
              variant="dark"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => navigate("/about#proposition")}
                variant="dark"
                className=" text-light"
              >
                Our Proposition
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/about#leadership")}
                className=" text-light"
              >
                Our Leadership
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/about#core-values")}
                className=" text-light"
              >
                Our Values
              </NavDropdown.Item>
            </NavDropdown> */}
            <NavItem route="careers" display="Careers" />
            <div className="d-block d-lg-none">
              <NavItem route="contact" display="Get Started" />
            </div>
          </Nav>
        </Navbar.Collapse>

        <div style={{ marginLeft: 20 }} className="d-none d-xl-block pr-4">
          <AnimatedLink
            to="/contact"
            className="btn bg-light text-primary text-bold m-auto"
            style={{ width: 150 }}
          >
            Get Started
          </AnimatedLink>
        </div>
      </Navbar>
    </>
  )
}

export default CustomNavbar
