import PropTypes from "prop-types"
import React from "react"
import NavBar from "./navBar"
const Header = ({ siteTitle, pageInfo }) => (
  <header>
    <NavBar pageInfo={pageInfo} />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
