import React from "react"
import { Nav } from "react-bootstrap"
import AnimatedLink from "./AnimatedLink"

export default function navItem({ route, display }) {
  return (
    <AnimatedLink to={`/${route}`} className="link-no-style">
      <Nav.Link as="span" eventKey={route}>
        {display}
      </Nav.Link>
    </AnimatedLink>
  )
}
