import React from "react"
import { Container, Popover, OverlayTrigger } from "react-bootstrap"
import timelineChevron from "../../static/images/timeline_chevron.svg"
import AnimatedLink from "../components/AnimatedLink"

export default function TimeLine() {
  const pStyle = {
    position: "absolute",
    top: "35%",
    height: "55%",
    width: "85%",
  }
  const pStyleSm = { paddingTop: "7%", paddingBottom: "7%" }

  const boxStyle = {
    position: "relative",
  }
  const popover = msg => (
    <Popover>
      <Popover.Content>{msg}</Popover.Content>
    </Popover>
  )

  return (
    <section id="timeline-boxes" className="py-5 bg-dark">
      <Container>
        <h2
          style={{ fontSize: "2.5rem" }}
          className="text-center text-primary text-bold"
        >
          Get Started
        </h2>
        <p className="lead-lg text-white text-center mb-5 d-none d-md-block">
          Experience how our AI products can help your company
        </p>
        <div
          className="d-none d-lg-flex "
          style={{ height: "30px", marginTop: 95 }}
        >
          <div
            style={{
              flex: "0 0 14%",
              ...boxStyle,
            }}
            className="text-light pl-3"
          >
            {" "}
            2 Hours
          </div>
          <div
            style={{
              flex: "0 0 15%",
              ...boxStyle,
            }}
            className="text-light pl-3"
          >
            1 Day
          </div>
          <div
            style={{
              flex: "0 0 20%",
              ...boxStyle,
            }}
            className="text-light pl-3"
          >
            4-5 Days
          </div>
          <div
            style={{
              flex: "0 0 21%",
              ...boxStyle,
            }}
            className="text-light pl-3"
          >
            6-12 Weeks
          </div>{" "}
          <div
            style={{
              flex: "0 0 32%",
              ...boxStyle,
            }}
            className="text-light pl-3"
          >
            4-6 Months
          </div>
        </div>
        <div
          className=" d-none d-lg-flex mb-5"
          style={{
            height: "160px",
            backgroundImage: `url(${timelineChevron})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div
            style={{
              flex: "0 0 17%",

              ...boxStyle,
            }}
            className="text-left text-light px-3                                                                  "
          >
            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              overlay={popover(
                "Get insights into Vulcan AI's capabilities and highest-value use cases for your business. Get a common understanding of your vision for Industry 4.0."
              )}
            >
              <p style={pStyle}>Capability Briefing &amp; Visioning</p>
            </OverlayTrigger>
          </div>
          <div
            style={{
              flex: "0 0 17%",

              ...boxStyle,
            }}
            className="text-left text-light px-3                                                                  "
          >
            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              overlay={popover(
                "Spend time with Vulcan's AI and Industry experts to chart out an Industry 4.0 Roadmap and identify high impact use cases to trial."
              )}
            >
              <p style={pStyle}>Value Discovery Workshop</p>
            </OverlayTrigger>
          </div>
          <div
            style={{
              flex: "0 0 20%",

              ...boxStyle,
            }}
            className="text-left text-light px-3                                                                  "
          >
            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              overlay={popover(
                "For the selected use cases, conduct feasibility assessment in terms of IoT readiness, availability of data, mapping of the right Vulcan components and the target state architecture."
              )}
            >
              <p style={pStyle}>Feasibility &amp; Tech Assessment</p>
            </OverlayTrigger>
          </div>
          <div
            style={{
              flex: "0 0 21%",

              ...boxStyle,
            }}
            className="text-left text-light px-3                                                                  "
          >
            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              overlay={popover(
                "For the selected use case(s), rapidly build AI application(s) on top of your company's data sets and prove the business case."
              )}
            >
              <p style={pStyle}>Real World Pilot</p>
            </OverlayTrigger>
          </div>
          <div
            style={{
              flex: "0 0 32%",

              ...boxStyle,
            }}
            className="text-left text-light px-3                                                                  "
          >
            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              overlay={popover(
                "Scale and deploy the AI Application into SPOCK Hyper-Data platform. Optimize and deploy inference models at the edge using Micro-Spock."
              )}
            >
              <p style={pStyle}>Enterprise Deployment</p>
            </OverlayTrigger>
          </div>
        </div>{" "}
      </Container>
      <div className="px-4">
        <p className="lead-lg text-white text-left mb-5 d-block d-md-none">
          Experience how our AI products can help your company
        </p>
        <div className="d-block d-lg-none" style={{ height: "450px" }}>
          <div className="d-block">
            <div
              style={{
                backgroundColor: "rgba(80,0,112, 1)",
                width: "60%",
                ...boxStyle,
              }}
              className="text-left text-light px-3 h-100 d-inline-block"
            >
              <p className="px-1 m-auto h-100" style={{ ...pStyleSm }}>
                Capability Briefing &amp; Visioning
              </p>
            </div>{" "}
            <div className="h-100 d-inline-block text-white pl-4">2 Hours</div>
          </div>
          <div className="d-block">
            <div
              style={{
                backgroundColor: "rgb(98,0,142)",
                width: "60%",
                ...boxStyle,
              }}
              className="text-left text-light px-3 h-100 d-inline-block"
            >
              <p className="px-1 m-auto h-100" style={{ ...pStyleSm }}>
                Value Discovery Workshop
              </p>
            </div>{" "}
            <div className="h-100 d-inline-block text-white pl-4">1 Day</div>
          </div>
          <div className="d-block">
            <div
              style={{
                backgroundColor: "rgb(122,46,159)",
                width: "60%",
                ...boxStyle,
              }}
              className="text-left text-light px-3 h-100 d-inline-block"
            >
              <p className="px-1 m-auto h-100" style={{ ...pStyleSm }}>
                Feasibility &amp; Tech Assessment
              </p>
            </div>{" "}
            <div className="h-100 d-inline-block text-white pl-4">4-5 Days</div>
          </div>
          <div className="d-block" style={{ height: "80px" }}>
            <div
              style={{
                backgroundColor: "rgb(145, 93, 177)",
                width: "60%",
                ...boxStyle,
              }}
              className="text-left text-light px-3 h-100 d-inline-block"
            >
              <p
                className="px-1 m-auto h-100"
                style={{
                  ...pStyleSm,
                  paddingTop: "25px",
                  paddingBottom: "25px",
                }}
              >
                Real World Pilot
              </p>
            </div>{" "}
            <div className="h-100 d-inline-block text-white pl-4">
              6-12 Weeks
            </div>
          </div>
          <div className="d-block">
            <div
              style={{
                backgroundColor: "rgb(160,113,190)",
                width: "60%",
                ...boxStyle,
              }}
              className="text-left text-light px-3 h-100 d-inline-block"
            >
              <p
                className="px-1 m-auto h-100 d-block d-sm-none"
                style={{
                  ...pStyleSm,
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                Enterprise Deployment
              </p>
              <p
                className="px-1 m-auto h-100 d-none d-sm-block"
                style={{
                  ...pStyleSm,
                  paddingTop: "25px",
                  paddingBottom: "25px",
                }}
              >
                Enterprise Deployment
              </p>
            </div>{" "}
            <div className="h-100 d-inline-block text-white pl-4">
              4-6 Months
            </div>
          </div>
        </div>
        <div className="w-100 d-none d-lg-block mb-5" style={{ marginTop: 95 }}>
          <AnimatedLink
            to="/contact"
            className="btn  bg-light text-primary text-bold  d-block m-auto"
            style={{ width: 150 }}
          >
            Get Started
          </AnimatedLink>
        </div>{" "}
        <div className="w-100 d-block d-lg-none" style={{ marginTop: 35 }}>
          <AnimatedLink
            to="/contact"
            className="btn  bg-light text-primary text-bold  d-block m-auto"
            style={{ width: 150 }}
          >
            Get Started
          </AnimatedLink>
        </div>
      </div>
    </section>
  )
}
